<template>
  <div v-if="isLoading">
    <b-spinner
      size="2rem"
      type="grow"
      small
      class="d-block mx-auto mt-3 text-primary"
    />
  </div>
  <div v-else-if="hasErrors">
    <div class="mt-3 text-danger text-center">
      {{ $t("error-message.load-failed") }}
    </div>
  </div>
  <div v-else-if="organization">
    <div class="d-flex align-items-center justify-content-center">
      <feather-icon
        v-if="canDelete"
        icon="Trash2Icon"
        size="20"
        style="cursor: pointer"
        class="text-primary ml-1 d-lg-none d-block"
        role="button"
        @click="isUnshareModalVisible = true"
      />

      <b-dropdown
        v-if="canEdit"
        id="dropdown-dropleft"
        variant="link"
        class="d-lg-none d-block"
        no-caret
      >
        <template #button-content>
          <feather-icon
            v-if="canEdit"
            v-b-tooltip.hover.bottom="$t('edit-dropdown.title')"
            icon="SettingsIcon"
            size="20"
            class="text-primary mx-auto d-block"
            style="padding: 0px !important"
          />
        </template>
        <b-dropdown-text class="bg-light">
          <p class="user-name font-weight-bolder mb-0">
            {{ $t("edit-dropdown.title") }}
          </p>
        </b-dropdown-text>
        <b-dropdown-item @click="isEditModalVisible = true">
          {{ $t("edit-dropdown.details") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canEdit"
          @click="callClassifiers()"
        >
          {{ $t("members.edit.classifiers.name") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canEdit"
          @click="callCFields()"
        >
          {{ $t("members.edit.custom-fields.label") }}
        </b-dropdown-item>
      </b-dropdown>
      <feather-icon
        v-if="canShare"
        v-b-tooltip.hover.bottom
        icon="Share2Icon"
        :title="$t('share.tooltip')"
        size="20"
        class="text-primary d-block d-lg-none"
        style="cursor: pointer"
        @click="isShareModalVisible = true"
      />
    </div>
    <unshare-item-modal
      v-model="isUnshareModalVisible"
      model-type="society"
      @remove-item="removeItem()"
      @modal-invisible="isUnshareModalVisible = false"
    />
    <!-- Dropdown big devices -->
    <div class="d-flex align-items-center justify-content-end edit-button">
      <feather-icon
        v-if="canShare"
        v-b-tooltip.hover.bottom
        icon="Share2Icon"
        :title="$t('share.tooltip')"
        size="20"
        :class="hasMenu ? 'edit-icon' : 'share-icon'"
        class="text-primary d-none d-lg-block p-0"
        style="cursor: pointer; margin-top: 0.84rem"
        @click="isShareModalVisible = true"
      />
      <feather-icon
        v-if="canDelete"
        icon="Trash2Icon"
        size="20"
        style="cursor: pointer"
        class="text-primary ml-1 d-none d-lg-block trash-icon"
        role="button"
        @click="isUnshareModalVisible = true"
      />
      <b-dropdown
        v-if="canEdit"
        id="dropdown-dropleft"
        variant="link"
        right
        :class="hasMenu ? 'edit-icon' : 'icons-menu'"
        class="d-none d-lg-block"
      >
        <template #button-content>
          <feather-icon
            v-if="canEdit"
            v-b-tooltip.hover.bottom="$t('edit-dropdown.title')"
            icon="SettingsIcon"
            size="20"
            class="text-primary"
            style="padding: 0px !important"
          />
        </template>
        <b-dropdown-text class="bg-light">
          <p class="user-name font-weight-bolder mb-0">
            {{ $t("edit-dropdown.title") }}
          </p>
        </b-dropdown-text>
        <b-dropdown-item @click="isEditModalVisible = true">
          {{ $t("edit-dropdown.details") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canEdit"
          @click="callClassifiers()"
        >
          {{ $t("members.edit.classifiers.name") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canEdit"
          @click="callCFields()"
        >
          {{ $t("members.edit.custom-fields.label") }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <profile-details
      :name="organization.name"
      :headline="organization.headline"
      :avatar-placeholder="avatarPlaceholder"
      :profile-image="organization.logoURL"
      :header-background="organization.backgroundURL"
      :can-edit="canEdit"
      has-action-buttons
      disable-chat
      :disable-meeting="!hasSlots"
      @edit="handleEditProfile"
      @request-meeting="isRequestMeetingVisible = true"
    >
      <!-- Profile tabs -->
      <b-tabs
        nav-wrapper-class="ml-md-3 pl-md-5 mt-n5"
        nav-class="ml-md-5 pl-4"
        content-class="mt-2"
        class="mt-n1"
      >
        <b-tab
          :title="$t('organizations.tabs.home-title')"
          title-link-class="px-0 pb-2 mt-n1 font-weight-bolder"
          active
        >
          <b-row class="flex-wrap px-1">
            <b-col
              xl="4"
              order="2"
              order-lg="1"
              class="px-0 pl-lg-0 pr-xl-2"
            >
              <!-- Profile Information -->
              <b-card>
                <b-card-title class="card-title">
                  {{ $t("organizations.information.information-title") }}
                </b-card-title>
                <hr>
                <div
                  v-if="!hasInformation"
                  class="d-flex flex-column justify-content-center align-items-center mb-1"
                >
                  <img class="placeholder" :src="aboutPlaceholder">
                  <p class="text-primary font-weight-bold">
                    {{ $t("members.no-about-message") }}
                  </p>
                </div>
                <div>
                  <div>
                    
                    <!-- Locations -->
                    <b-row
                      v-if="
                        organization.locations &&
                          organization.locations.length > 0
                      "
                      class="mb-50"
                    >
                      <b-col class="font-weight-bold">
                        {{ $t('backoffice.settings.subtitle-locations') }}
                      </b-col>
                      <b-col>
                        <div v-for="location in organization.locations" :key="location.key">
                          <small class="text-muted mb-0">{{ location.label }}</small>
                          <p>{{ location.address }}</p>
                        </div>
                      </b-col>
                    </b-row>
                    
                    <!-- WEBSITE and LINKS -->
                    <div
                      v-if="organization.links && organization.links.length > 0"
                      class="mb-50"
                    >
                      <b-row 
                        v-for="link in organization.links" 
                        :key="link.key"
                      >
                        <b-col
                          v-if="link.label === 'Website'"
                          class="font-weight-bold mb-50"
                        >
                          {{ $t('backoffice.settings.website') }}
                        </b-col>
                        <b-col
                          v-if="link.label === 'Website'"
                          class="website-url mb-50"
                        >
                        <small class="text-muted mb-0">{{ link.name }}</small>
                        <p>
                          <a class="mb-0" :href="link.url" target="_blank">
                            {{ link.url }}
                          </a>
                        </p>
                        </b-col>
                      </b-row>
                    </div>

                    <!-- Emails -->
                    <b-row
                      v-if="
                        organization.emails && organization.emails.length > 0 && !currentCollectiveKey"
                      class="mb-50"
                    >
                      <b-col class="font-weight-bold">
                        {{ $t('backoffice.settings.subtitle-emails') }}
                      </b-col>
                      <b-col>
                        <div v-for="email in organization.emails" :key="email.key">
                          <small class="text-muted mb-0">{{ email.label }}</small>
                          <p><a target="_blank" :href="`mailto:${email.email}`">{{ email.email }}</a></p>
                        </div>
                      </b-col>
                    </b-row>

                    <!-- Phones -->
                    <b-row
                      v-if="
                        organization.phones && organization.phones.length > 0
                      "
                      class="mb-50"
                    >
                      <b-col class="font-weight-bold">
                        {{ $t('backoffice.settings.subtitle-phones') }}
                      </b-col>
                      <b-col>
                        <div v-for="(phone, index) in organization.phones" :key="index">
                          <small class="text-muted mb-0">{{ phone.label }}</small>
                          <p :class="organization.phones.length > 1?'mb-25':''">
                            <a 
                              v-if="phone.country && phone.country.phoneCode" 
                              :href="`tel:+${phone.country.phoneCode}${phone.phone}`"
                            >
                              +{{ phone.country.phoneCode }} {{ phone.phone }}
                            </a>
                            <a v-else :href="`tel:+${phone.phone}`">
                              {{ phone.phone }}
                            </a>
                          </p>
                        </div>
                      </b-col>
                    </b-row>

                    <!-- DATE FOUNDED -->
                    <b-row v-if="organization.dateFounded" class="mb-50">
                      <b-col class="font-weight-bold">
                        {{ $t("organizations.information.foundationDate") }}
                      </b-col>
                      <b-col>
                        {{ $d(organization.dateFounded) }}
                      </b-col>
                    </b-row>

                    <!-- EMPLOYEES -->
                    <b-row v-if="organization.employeeNumber" class="mb-50">
                      <b-col class="font-weight-bold">
                        {{ $t("organizations.information.numberEmployees") }}
                      </b-col>
                      <b-col>
                        {{ organization.employeeNumber }}
                      </b-col>
                    </b-row>

                    <!-- COUNTRIES -->
                    <b-row
                      v-if="organization.countries && organization.countries.length > 0"
                      class="mb-50"
                    >
                      <b-col class="font-weight-bold">
                        {{ $t("organizations.information.countries") }}
                      </b-col>
                      <b-col>
                        <p
                          v-for="(location, index) in organization.countries"
                          :key="index"
                          class="mb-0"
                        >
                          <!-- In copernic-common email returns: email: string -->
                          {{ location.country }}
                        </p>
                      </b-col>
                    </b-row>

                    <!--Custom fields-->
                    <div
                      v-for="(field, index) in organization.custom"
                      :key="index"
                    >
                      <b-row
                        v-if="
                          field.value ||
                            (typeof field.value !== 'string' && !field.value)
                        "
                        class="mb-1"
                      >
                        <b-col class="font-weight-bold">
                          {{ getName(field) }}:
                        </b-col>
                        <b-col>
                          {{ getValue(field.value) }}
                        </b-col>
                      </b-row>
                    </div>

                    <!-- Classifiers -->
                    <template
                      v-if="classifiers && classifiers.length > 0"
                      class="my-50"
                    >
                      <div
                        v-for="(value, name) in sortedClassifiers"
                        :key="value.key"
                      >
                        <div v-if="!currentCollectiveKey" class="w-100">
                          {{ name }}:
                        </div>
                        <div v-if="!currentCollectiveKey">
                          <span v-for="(v, index) in value" :key="index">
                            <b-badge
                              class="mb-50 mr-50"
                              :style="classifierStyle(v)"
                            >
                              {{
                                translateTranslationTable(
                                  $store.state.locale.currentLocale,
                                  v.name
                                )
                              }}
                            </b-badge>
                          </span>
                        </div>
                        <b-row v-else class="mt-50">
                          <b-col v-if="currentCollectiveKey" class="w-100 font-weight-bold">
                            {{ $t("services.title") }}
                          </b-col>
                          <b-col>
                            <span v-for="(v, index) in value" :key="index">
                              <p class="mb-0">
                                {{
                                  translateTranslationTable(
                                    $store.state.locale.currentLocale,
                                    v.name
                                  )
                                }}
                              </p>
                            </span>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                    <hr
                      v-if="organization.links && organization.links.length"
                    >
                    <!-- Social media icons -->

                    <b-row
                      v-if="organization.links && organization.links.length > 0"
                      class="d-flex ml-0"
                    >
                      <div v-for="link in organization.links" :key="link.key">
                        <div v-if="link.label !== 'Website'" class="mt-50">
                          <a
                            v-b-tooltip.hover.bottom="link.label"
                            class="mb-0 mr-1 d-flex flex-column social rounded-circle p-75"
                            target="_blank"
                            :href="toAbsoluteURL(link.url)"
                          >
                            <feather-icon
                              :icon="
                                hasSocialIcon(link.label)
                                  ? `${link.label}Icon`
                                  : 'GlobeIcon'
                              "
                              size="20"
                            />
                          </a>
                        </div>
                      </div>
                    </b-row>
                  </div>
                </div>
              </b-card>
              <b-card
                v-if="organization && organization.locations.length > 0"
                :title="$t('backoffice.settings.subtitle-locations')">
                <hr></hr>
                <places-map
                  ref="map"
                  :placeholder="$t('organizations.edit.locations.placeholder')"
                  :locations="organization.locations"
                  class="mt-2"
                  style="height: 400px"
                />
              </b-card>
              <!-- Twitter Feed-->
              <div v-if="hasTwitterFeed" class="vh-75">
                <twitter-feed-widget :src="twitterSrc" />
              </div>
            </b-col>
            <b-col
              xl="8"
              order="1"
              order-lg="2"
              class="px-0 pr-lg-0"
            >
              <div>
                <!--About-->
                <b-card>
                  <b-card-title class="card-title">
                    {{ $t("organizations.about-title") }}
                  </b-card-title>
                  <hr>
                  <content-with-anchors
                    v-if="organization.description"
                    tag="p"
                    :content="translatedDescription"
                  />
                  <div
                    v-if="!translatedDescription"
                    class="d-flex flex-column justify-content-center align-items-center mb-1"
                  >
                    <img class="placeholder" :src="aboutPlaceholder">
                    <p class="text-primary font-weight-bold">
                      {{ $t("members.no-about-message") }}
                    </p>
                  </div>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Contacts -->
        <b-tab
          v-if="contactsApp"
          :title="contactCustomTitle || $t('contacts.title')"
          title-link-class="ml-3 px-0 pb-2 mt-n1 font-weight-bolder"
        >
          <b-row>
            <b-col>
              <contacts :society-key="organization.key" />
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>

      <organization-edit-modal
        v-model="isEditModalVisible"
        :organization="organization"
        :classifiers="classifiers"
        @profile-updated="handleProfileUpdated"
      />

      <calendar-event-slots-form-modal
        v-model="isRequestMeetingVisible"
        :target="organization"
        target-type="organization"
      />

      <classifiers-modal
        v-if="isCallingClassifiers"
        v-model="isClassifiersModalVisible"
        :item-key="organization.key"
        morph-type="society"
        model-type="society"
        :classifiers="classifiers"
        @profile-updated="handleProfileUpdated"
      />

      <!-- sortedCustomFields -->
      <customfields-modal
        v-if="isCallingCFields"
        v-model="isCustomFieldsModalVisible"
        :item="organization"
        morph-type="society"
        @profile-updated="handleProfileUpdated"
      />

      <share-modal
        v-model="isShareModalVisible"
        :item-name="organization.name"
        :can-share="canShare"
        model-type="organization"
        :model-key="organization.key"
        type="organizations"
      />
    </profile-details>
  </div>
</template>

<script>
import { checkPermissions } from '@/@core/utils/roles-utils';
import OrganizationsPlaceholder from '@/assets/images/placeholders/light/organizations-logo.svg';
import AboutPlaceholder from '@/assets/images/placeholders/light/aboutCommunity.svg';
import PhotoPlaceholder from '@/assets/images/placeholders/light/photo.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ProfileDetails from '@core/components/profile/ProfileDetails.vue';
import TwitterFeedWidget from '@/views/apps/twitter/widgets/TwitterFeedWidget.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import ContentWithAnchors from '@core/components/string-utils/ContentWithAnchors.vue';
// import MembersListWidget from '@/views/apps/member/widgets/MembersListWidget.vue';
import OrganizationEditModal from '@/views/apps/organization/components/OrganizationEditModal.vue';
import CalendarEventSlotsFormModal from '@/views/apps/calendar/components/modals/CalendarEventSlotsFormModal.vue';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import {
  OrganizationListingType,
} from '@copernicsw/community-common';
import ShareModal from '@/@core/components/modal/ShareModal.vue';
import { dateValidator } from '@/@core/utils/validations/validators';
import UnshareItemModal from '@/@core/components/modal/UnshareItemModal.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { toAbsoluteURL } from '@core/utils/url-utils';
import Contacts from '@/views/apps/contacts/widgets/ContactsListWidget.vue';
import PlacesMap from "@core/components/places-map-input/PlacesMap.vue";

export default {
  components: {
    ProfileDetails,
    TwitterFeedWidget,
    ContentWithAnchors,
    PlacesMap,
    OrganizationEditModal,
    CalendarEventSlotsFormModal,
    ClassifiersModal,
    CustomfieldsModal,
    UnshareItemModal,
    ShareModal,
    ToastificationContentVue,
    Contacts,
  },
  mixins: [ToastNotificationsMixin, WidgetLayoutMixin],
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      hasErrors: false,
      isEditModalVisible: false,
      isRequestMeetingVisible: false,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      isShareModalVisible: false,
      isSmall: false,
      aviso: false,
      camposVacios: {},
      currentType: '',
      isUnshareModalVisible: false,
      isCallingClassifiers: false,
      isCallingCFields: false,
    };
  },
  setup() {
    return {
      aboutPlaceholder: AboutPlaceholder,
      photosPlaceholder: PhotoPlaceholder,
      avatarPlaceholder: OrganizationsPlaceholder,
    };
  },
  computed: {
    currentCollectiveKey() {
      return this.$store.getters.currentCollective.key === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5';
    },
    cFields() {
      return this.$store.getters.customFields;
    },
    key() {
      return this.$route.params.organizationId;
    },
    type() {
      if (this.$route.params.type != null) {
        return this.$route.params.type;
      }
      return this.communitySlug;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    organization() {
      if (this.$store.getters.organizations[this.type]) {
        return this.$store.getters.organizations[this.type].unpaginated[0];
      }
      return {};
    },
    translatedDescription() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.organization.description,
      );
    },
    hasInformation() {
      return (
        this.organization.locations?.length > 0
        || this.organization.emails?.length > 0
        || (this.organization.phones && this.organization.phones.length > 0)
        || (this.organization.dateFounded
          && this.organization.dateFounded !== null
          && typeof this.organization.dateFounded !== 'undefined')
        || (this.organization.employeeNumber
          && this.organization.employeeNumber !== null
          && typeof this.organization.employeeNumber !== 'undefined')
        || (this.organization.links && this.organization.links.length > 0)
        || (this.classifiers && this.classifiers.length > 0)
        || this.hasCustomFields
      );
    },
    twitterSrc() {
      const twitterLink = (this.organization?.links || []).find(
        ({ site }) => site === 'Twitter',
      );
      return twitterLink?.url;
    },
    hasTwitterFeed() {
      return (
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].twitter
        && this.twitterSrc
      );
    },
    membersApp() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].members;
    },
    contactsApp() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].contacts;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canEdit() {
      return (
        this.organization.isAdmin
        || this.$store.getters.currentCollective.isStaff
        || checkPermissions(
          'update',
          'society',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canShare() {
      return (
        this.organization.isAdmin
        || this.$store.getters.currentCollective.isStaff
        || checkPermissions(
          'share',
          'society',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canDelete() {
      return (
        this.organization.isAdmin
        || this.$store.getters.currentCollective.isStaff
        || checkPermissions(
          'delete',
          'society',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    hasSlots() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
        .meetingsSlots;
    },
    hasMembers() {
      return this.organization.numOfMembers > 0;
    },
    classifiersData() {
      if (this.$store.getters.classifiers.organization) {
        return this.$store.getters.classifiers.organization.unpaginated;
      }
      return [];
    },
    classifiers() {
      return this.organization.classifiers;
    },
    hasMenu() {
      return (
        this.$store.getters.currentCollective.menu
        && this.$store.getters.currentCollective.menu.length > 0
      );
    },
    sortedClassifiers() {
      const newObject = {};
      this.classifiers?.forEach((item) => {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ].push(item);
        }
      });
      return newObject;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    hasCustomFields() {
      return this.organization?.customFields?.some((item) => item.value !== '');
    },
    appId() {
      return 123;
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName
      );
    },
    app() {
      return Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).find(({ id }) => id === this.appId)
    },
    contactCustomTitle() {
      if (this.contactsApp) {
        const contactApp = Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).find(({ id }) => id === 120)
        return translateTranslationTable(
          this.$store.state.locale.currentLocale,
          contactApp?.customizationName,
        );
      }

      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.computedAppNames,
      );
    },
    fields() {
      return [
        {
          key: 'fullname',
          label: this.$t('user.title'),
        },
        {
          key: 'sale',
          label: this.$t('organizations.sale'),
        },
        {
          key: 'date',
          label: this.$t('organizations.date'),
        },
      ];
    },
  },
  watch: {
    organizationId() {
      this.fetchOrganization();
    },
    classifiers(newValue) {
      return newValue;
    },
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    await this.fetchOrganization();
    this.fetchClassifiers();
    await this.fetchCustomFields();
    this.isComplete();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async removeItem() {
      await this.$store.dispatch('deleteOrganization', {
        item: {
          itemType: 'organizations',
          requestConfig: {
            organizationKey: this.organization.key,
          },
        },
      });
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: this.$t('unshare.toast-messages.success', { item: 'Forum' }),
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      });
      this.$router.push(`/${this.collective.slug}/societies`);
    },
    async fetchCustomFields() {
      await this.$store.dispatch('fetchCustomFields', {
        modelType: 'organization',
      });
    },
    isComplete() {
      this.aviso = false;
      // Si no existe este campo, no se devuelve el campo desde BE, asi que en ese caso se inicializa como null
      if (this.organization?.dateFounded === undefined) {
        this.organization.dateFounded = null;
      }
      for (const campo in this.organization) {
        if (
          campo === 'name'
          || campo === 'headline'
          || campo === 'description'
          || campo === 'employeeNumber'
          || campo === 'dateFounded'
          || campo === 'locations'
          || campo === 'links'
          || campo === 'logoURL'
          || campo === 'backgroundURL'
        ) {
          if (
            this.organization[campo] === null
            || this.organization[campo] === ''
            || this.organization[campo] === undefined
            || this.organization[campo] === 0
          ) {
            this.aviso = true;
          }
        }
      }
    },
    async fetchOrganization() {
      this.updateBreadcrumbs();
      this.currentType = this.type ? this.type : this.communitySlug;

      await this.$store.dispatch('getItems', {
        itemType: 'organizations',
        forceAPICall: true,
        storedKey: this.currentType,
        page: 1,
        perPage: 15,
        requestConfig: {
          count: 15,
          listingType: OrganizationListingType.forCommunity,
          organizationKey: [this.key],
        },
      });
      this.isLoading = false;
    },
    hasSocialIcon(site) {
      // Social media icons: Instagram, Facebook, Linkedin, Youtube, Twitter. The rest with globe icon
      // Social media names obtained from @copernicsw/community-common/link/
      // TODO: Replace by enum when commos is updated.
      return [
        'Instagram',
        'Facebook',
        'Linkedin',
        'Youtube',
        'Twitter',
      ].includes(site);
    },
    handleEditProfile() {
      this.isEditModalVisible = true;
    },
    async handleProfileUpdated() {
      await this.fetchOrganization();
      await this.fetchClassifiers();
      this.isComplete();
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || 'societies.breadcrumb-text.societies-list',
          to: { name: 'societies' },
        },
        {
          text: this.organization
            ? this.organization.name
            : 'societies.breadcrumb-text.society',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        storedKey: 'organization',
        itemType: 'backoffice/classifiers',
        customName: 'classifiers',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          societyKey: this.organization?.key,
        },
      });
      this.updateBreadcrumbs();
    },
    translatedFieldName(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field.name,
      );
    },
    valueType(field) {
      if (field.type === 'boolean') {
        if (field.value === 1) {
          return this.$t(
            'organizations.edit.custom-fields.boolean-response.true',
          );
        }
        return this.$t(
          'organizations.edit.custom-fields.boolean-response.false',
        );
      }
      return '';
    },
    translateTranslationTable,
    icon(classifier) {
      return classifier.iconClass.split('-').pop();
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        'background-color': classifier.backgroundColor,
        ...(this.currentCollectiveKey ? { 'font-weight': '400', 'font-size': '14px' } : { 'font-weight': '600' }),
      };
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    boLocation() {
      return this.$router.push({
        name: 'organization-settings',
        params: {
          organizationId: this.$route.params.organizationId,
          communityId: this.$route.params.communityId,
        },
      });
    },
    getName(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field.customFieldName,
      );
    },
    async handleCustomFields() {
      await this.fetchData();
    },
    getValue(value) {
      if (dateValidator(value)) {
        return value.split('-').reverse().join('-');
      }
      if (value === true) {
        return this.$t('yes');
      }
      if (value === false) {
        return this.$t('no');
      }
      return value;
    },
    toAbsoluteURL,
    callClassifiers() {
      this.isCallingClassifiers = true;
      this.isClassifiersModalVisible = true;
    },
    callCFields() {
      this.isCallingCFields = true;
      this.isCustomFieldsModalVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/page-profile.scss";
.placeholder {
  height: 6.5rem;
}
.card-title {
  // necessary the important to remove the card title margin botton from the general cards
  margin-bottom: 0 !important;
}
.website-url {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.social {
  background-color: rgba($primary, 0.12);
}
.edit-icon {
  position: absolute;
  top: -25px;
  right: 50px;
  cursor: pointer;
}
.icons-menu {
  position: absolute;
  top: -60px;
}
.edit-icon-mobile {
  top: -27px !important;
}
.share-icon-mobile {
  top: -27px;
}
.bo-icon {
  position: absolute;
  top: 174px;
  right: 28px;
  cursor: pointer;
}
.share-icon {
  position: absolute;
  top: -59px;
  right: 123px;
  cursor: pointer;
}
.dropdown-member {
  [dir] .dropdown-menu[x-placement="bottom-start"],
  [dir] .dropdown-menu[x-placement="bottom-end"] {
    margin-top: 50 !important;
  }
}
.edit-button {
  position: absolute;
  right: 0px;
}
.trash-icon {
  position: absolute;
  right: 80px;
  top: -48px;
}
</style>
